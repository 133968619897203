@font-face {
  font-family: "Josefin";
  src: local("JosefinSans"), url(../fonts/JosefinSans-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "Mark";
  src: local("Mark"), url(../fonts/Mark-Heavy.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Mark";
  src: local("Mark"), url(../fonts/Mark-Medium.ttf) format("truetype");
  font-weight: normal;
}

@import "var.scss";

* {
  font-family: Josefin, Helvetica, sans-serif;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

::-webkit-scrollbar {
  // width: unset !important;
  width: 13px;
  height: 13px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 55, 100, 0.3);
  border-radius: 0.25em;
}

::-webkit-scrollbar-thumb {
  background-color: $darkAccentColor;
  border-radius: 0.25em;
}


button {
  cursor: pointer;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.pageList {
  display: flex;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  align-items: center;
  position: absolute;
  top: 0;
  height: 80px;
  justify-content: center;

  &.list {
    h1 {
      width: 180px;
    }
  }

  h1 {
    width: 380px;
    margin-right: auto;
    font-weight: 300;
    padding-left: 10px;

    &.long {
      font-size: 1.5em;
    }
  }

  .selectWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    margin: 10px;
    color: $darkFontColor;
    background-color: $lightFontColor;
    font-size: 14px;
    height: 50px;
    text-transform: uppercase;
    transition: all 100ms ease-in-out;
    padding: 10px 0;
    position: relative;
    overflow: visible;
    cursor: pointer;

    .prefix {
      margin-right: auto;
      position: absolute;
      left: 0;
      padding: 0 10px;
      top: 50%;
      font-weight: 300;
      transform: translateY(-50%);
      color: $darkFontColor;
      z-index: 1;
    }

    .select {
      text-transform: uppercase;
      display: flex;
      justify-content: left;
      align-items: center;
      height: 50px;
      background-color: $lightFontColor;
      color: $darkFontColor;
      transition: all 100ms ease-in-out;
      padding-left: 118px;
      padding-right: 15px;
      width: 100%;
      position: relative;
      overflow: visible;
      border: 1px solid;

      .categoryName {
        display: block;
        width: 100%;
        padding-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        fill: $darkFontColor;
        margin-left: auto;
        font-size: 18px;
      }
      
      .options {
        position: absolute;
        top: 100%;
        background-color: $lightFontColor;
        z-index: 1;
        border: solid 1px;

        div {
          padding: 5px;
          cursor: pointer;

          &:hover {
            // background-color: $buttonColor;
            text-decoration: underline;
          }
        }
      }
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    margin: 10px;
    border: none;
    outline: none;
    background: $buttonColor;
    color: $lightFontColor;
    font-size: 14px;
    height: 50px;
    text-transform: uppercase;
    transition: all 100ms ease-in-out;

    svg {
      height: 50px;
      width: 24px;
      fill: $lightFontColor;
      transition: all 100ms ease-in-out;
      margin-right: 12px;
      margin-left: -12px;
    }

    &:hover, &.active {
      background-color: $buttonHighlight;
    }
  }
}

.pageSection {
  position: absolute;
  color: $darkFontColor;
  top: 80px;
  bottom: 10px;
  display: flex;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  justify-content: center;
  padding: 20px;

  &.list,
  &.categories {
    padding-top: 60px;

    .selector {
      position: absolute;
      top: 0px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 30px);
      margin: 5px 15px;

      button {
        border: none;
        outline: none;
        background: none;
        font-size: 20px;
        font-family: inherit;
        font-weight: 600;

        &:disabled {
          cursor: default;

          &:hover {
            border-bottom: none;
          }
        }

        &:hover {
          border-bottom: solid 1px;
        }
      }

      &.cat {
        height: 30px;
        top: 14px;
      }
    }
  }

  &.map {
    max-width: 1440px;
  }

  .tenantList {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    bottom: 10px;
    width: 100%;
    padding: 0 20px 0 150px;
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;

    .section {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      position: relative;
    }

    .tenantBlock {
      height: 300px;
      width: 100%;
      max-width: 180px;
      border: solid 2px $darkAccentColor;
      margin: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .logo img {
        width: 100%;
        padding: 0 10px;
        height: 160px;
        margin: 10px auto;
        object-fit: contain;
        border-bottom: 1px solid $darkAccentColor2;
      }

      .name,
      .phone,
      .location {
        width: 100%;
        text-align: center;
        padding: 5px 10px;
        text-transform: uppercase;
        font-size: 14px;
      }

      .name {
        font-weight: 700;
        margin-top: auto;
      }

      .name.long {
        font-size: 12px;
      }

      .phone {
        margin-top: auto;
        position: relative;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
          height: 16px;
          width: 11px;
          margin-right: 3px;
        }
      }

      .location {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: $darkAccentColor2;
        color: $lightFontColor;
        height: 39px;
        font-weight: 500;

        img {
          position: absolute;
          height: 16px;
          width: 11px;
          left: 15px;
          top: 10px;
        }
      }

      &.header {
        font-weight: 400;
        position: absolute;
        top: 0;
        left: -150px;
        width: 150px;
        background: none;
        border: none;
        cursor: default;

        .content {
          margin: 5px 5px auto auto;
          text-align: right;
          font-size: 40px;
          margin-right: 50px;
        }
      }
    }
  }
}

* {
  box-sizing: border-box;
}

.error-message {
  display: flex;
  height: 100vh;

  h1 {
    margin: auto;
  }
}

.mapView {
  text-align: center;
  display: flex;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  left: 10px;
  overflow: hidden;
}

.sidebar {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  position: relative;
  margin: 0px;
  padding-right: 10px;

  h1,
  h2 {
    text-align: left;
    padding-left: 5px;
    margin: 0;
    font-weight: 600;
  }

  h1 {
    margin-bottom: 40px;
  }

  select,
  input {
    outline: none;
    border: none;
    background: #ddd;
    padding: 5px;
    font-family: inherit;
    width: 100%;
  }

  select {
    font-weight: bold;
    font-size: 14px;
  }

  .search-wrapper {
    position: relative;
    background-color: #ddd;
    display: flex;
    padding: 3px;

    input {
      flex-grow: 1;
    }

    &::before {
      content: "";
      display: inline-block;
      position: relative;
      color: grey;
      width: 25px;
      background-image: url("../magnify.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }

  .subheading {
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .tenant-list {
    overflow-y: auto;
    margin: 5px 0px;
    padding-bottom: 50px;

    .tenant-list-button {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 0em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 0.25em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $darkAccentColor;
      border-radius: 0.25em;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 50px;
      background: rgb(239, 239, 239);
      background: linear-gradient(0deg, rgba(239, 239, 239, 1) 25%, rgba(239, 239, 239, 0) 100%);
      pointer-events: none;
    }

    &.hide-categories {
      .tenant-button {
        border-top: solid 1px #333 !important;
      }

      .category-heading {
        display: none;
      }

      .category-listings:first-of-type {
        .tenant-button:first-child {
          border: none !important;
        }
      }
    }
  }

  .category-listings {
    display: block;

    .tenant-button {
      all: unset;
      display: block;
      padding: 10px;
      outline: none;
      border: none;
      border-top: solid 1px #333;
      text-align: left;
      font-size: 14px;
      transition: all 300ms ease-in-out;
      font-family: inherit;
      width: calc(100% - 20px);
      background-color: #ddd;

      &.active {
        background-color: #bbb;
      }

      &:first-child {
        border: none;
      }

      .readMore {
        margin-top: 10px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  .category-heading {
    background-color: $darkAccentColor;
    color: $lightFontColor;
    font-size: 14px;
    padding: 10px;
    text-align: left;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}

.map3d-container {
  max-width: unset !important;
  position: relative;
  margin: 0 !important;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

.flat-flyout {
  color: $lightFontColor !important;
  background-color: $darkAccentColor !important;
  white-space: nowrap;
  height: 24px !important;
  span {
    color: inherit !important;
  }

  &.destination::after {
    border-left: 15px solid transparent !important;
    border-right: 15px solid transparent !important;
    border-top: 15px solid $darkAccentColor !important;
  }
}

#renderCanvas {
  width: 100%;
  height: 100%;
  outline: none;
}

.map3d-loading {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 101;
  display: flex;
  background-color: $lightFontColor;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  pointer-events: none;

  &.isLoading {
    opacity: 1;
  }

  & > * {
    margin: auto;
  }
}

.mobile {
  .sidebar {
    width: 100%;
    padding: 10px;
    height: unset;
    display: grid;
    grid-template-areas:
      "shop floor"
      "tenant tenant";
    gap: 10px;
    position: absolute;
    background-color: $lightFontColor;
    z-index: 1;
    left: 0;

    h2 {
      font-size: 18px;
    }

    .search-section {
      grid-area: shop;
    }

    .floor-section {
      grid-area: floor;

      select {
        display: block;
        width: 100%;
        height: 33px;
      }
    }

    .tenant-list {
      grid-area: tenant;
      width: 100%;
      padding-bottom: 0;
      overflow: visible;
      position: relative;

      .category-heading {
        overflow-x: visible;
      }

      .tenant-list-button {
        height: 33px;
        width: 100%;
        outline: none;
        border: none;
        font-family: inherit;
        font-size: 14px;
        background: #eee;
        display: flex;
        align-items: center;
        justify-content: left;
        position: relative;
        flex: 1;

        &::after {
          content: "▼";
          position: absolute;
          right: 10px;
          top: 8px;
        }

        &.down::after {
          content: "▲";
        }
      }

      .tenant-list-wrap {
        display: none;

        &.show {
          display: flex;
          flex-direction: column;
          position: absolute;
          flex-basis: auto;
          left: 0;
          right: 0;
          top: 33px;
          z-index: 2;
          max-height: calc(100vh - 166px);
          overflow-y: scroll;
          flex: 1;
        }
      }

      &::after {
        display: none;
      }
    }
    h1 {
      display: none;
    }
  }

  .map3d-container {
    max-height: 100vh;
  }
  
  .pageList {
    button {
      font-size: 14px;
      width: 150px;

      svg {
        width: 18px;
        margin-left: -9px;
      }
    }

    .listButton {
      display: none;
    }

    .selectWrapper {
      width: 250px;
      font-size: 14px;

      .categoryName {
        width: 120px;
      }
    }

    h1 {
      display: none;
    }
  }

  .tenantList {
    padding: 0 5px 0 25px;

    .tenantBlock {
      width: 130px;
      height: 250px;
      margin: 2px;

      &.header {
        position: relative;
        left: unset;
        width: 100%;
        max-width: unset;
        height: 40px;
        justify-content: center;

        .content {
          width: 100%;
          font-size: 24px;
          margin: unset;
          text-align: left;
        }
      }

      .logo {
        img {
          height: 100px;
        }
      }

      .location img {
        display: none;
      }
    }
  }

  .pageSection.categories .tenantBlock.header {
    .content {
      font-size: 18px;
    }
  } 

  .pageSection.list {
    padding-top: 80px;

    .selector {
      flex-wrap: wrap;
      height: 80px;
      button {
        font-size: 18px;
      }
    }

    .tenantList {
      top: 80px;
    }
  }

  .pageSection.tenant {
    padding: 0;
  }

  .tenantWrapper {
    flex-direction: column;

    .side {
      width: 100%;
      height: 125px;
      flex-direction: row;
      border-bottom: 1px solid #BDBDBD;
      border-right: none;
      padding: 5px;

      .logo {
        width: 120px;
        height: 120px;
        border-bottom: none;

        img {
          width: 120px;
          height: 120px;
        }
      }

      .description {
        display: flex;
        flex-direction: column;

        .block {
          display: flex;
          flex-direction: column;

          h2 {
            margin: 0;
            margin-bottom: 2px;
            font-size: 1.2em;
          }

          &:not(:first-child) {
            padding-top: 5px;
            border-top: 1px solid #BDBDBD;
          }
        }
      }
    }

    .tenant {
      width: 100%;
      height: unset;
      padding: 0;

      .descriptionBlock {
        padding: 10px;
      }

      .map3d-container {
        width: 100%;
      }
    }
  }
}

@media (max-width: 460px) {
  .pageList {
    .selectWrapper {
      width: 150px !important;
      font-size: 12px !important;
      .prefix {
        display: none;
      }
  
      .select {
        padding-left: 15px;
      }
    }
  }
}

.northIcon {
  bottom: 15px;
  right: 25px;
  width: 60px;
  height: 60px;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
}