@import "var.scss";

.tenantWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  align-items: center;

  .side {
    width: 250px;
    height: calc(100% - 10px);
    border-right: 1px solid #BDBDBD;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;

    .logo {
      width: 220px;
      height: 240px;
      border-bottom: 1px solid #BDBDBD;

      img {
        width: 220px;
        height: 220px;
        object-fit: contain;
      }
    }

    .description {
      width: 100%;
      font-weight: 300;
      font-size: 16px;
      padding-left: 10px;

      h2 {
        margin-top: 30px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 4px;
      }
    }

    .link {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 10px 0;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        border: none;
        outline: none;
        background: $buttonColor;
        color: $lightFontColor;
        font-size: 12px;
        height: 30px;
        text-transform: uppercase;
        transition: all 100ms ease-in-out;
        margin-right: 10px;
        text-decoration: none;
        font-weight: 400;
        padding-top: 2px;

        &:hover {
          background-color: $buttonHighlight;
        }
      }
    }
  }

  .tenant {
    height: 100%;
    padding: 20px;
    flex-grow: 1;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    
    .share {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 10px 0;
      
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        border: none;
        outline: none;
        background: $buttonColor;
        color: $lightFontColor;
        font-size: 12px;
        height: 30px;
        text-transform: uppercase;
        transition: all 100ms ease-in-out;
        margin-right: 10px;

        svg {
          height: 50px;
          width: 24px;
          fill: $lightFontColor;
          transition: all 100ms ease-in-out;
          margin-right: 12px;
          margin-left: -12px;
        }

        &:hover, &.active {
          background-color: $buttonHighlight;
        }
      }
    }

    h2 {
      margin-top: 30px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    .map3d-container {
      // height: 440px;
      flex-grow: 1;
      width: 650px;
      border: solid 1px;
    }
  }
}