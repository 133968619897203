// Accent colors
$highlightColor: #ffa600;
$buttonHighlight: #000;
$darkAccentColor: #003764;
$darkAccentColor2: #35495A;
$buttonColor: #828282;

// Font Colors
$lightFontColor: #ffffff;
$darkFontColor: #4d4d4d;
